import flatpickr from 'flatpickr';

function setupDatePicker() {
  flatpickr('.flatpickr-input-group', {
    altInput: true,
    altInputClass: 'form-control input flatpickr-alt-input',
    altFormat: 'm/d/Y',
    dateFormat: 'Y-m-d',
    wrap: true,
    allowInput: true,
  });
}

$(document).ready(function() {
  setupDatePicker();
});

import bootbox from 'bootbox';

!function($) {
  $(document).ready(function() {
    // indicate external links for accessibility
    $('a[target="_blank"]').attr('title', 'Opens in a new tab').append('<i class="fas fa-external-link-alt"></i>');

    // indicate glossary links for accessibility
    $('a.glossary').attr('title', 'Click for definition').append('<i class="fas fa-question-circle"></i>');

    // event handler to load glossary and open dialog when you click on a glossary link
    $("body").on("click", "a.glossary", function(event){
      // callback used after loading content
      function openDialog(){
        var pageFragment = $(this);
        var header = pageFragment.find("h2");

        var dialogTitle = header.text();
        header.remove();

        var content = pageFragment.html();

        var modal = bootbox.dialog({
          message: content,
          title: dialogTitle,
          backdrop: true,
          keyboard: true,
          onEscape: function() {},
        });
      }

      var url = $(this).attr("href");

      // Switch the URL from "key-terms#B100" to "key-terms #B100" for
      // compatibility with jQuery's Ajax .load() page fragment feature.
      url = url.replace(/#/, " #");

      // add glossary content into div, then use the callback to open it
      $("<div>").load(url, openDialog);

      return false; // don't follow the hyperlink, if there is one
    });
  });
}(jQuery);

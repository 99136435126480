import Rails from '@rails/ujs';
import jQuery from 'jquery';
import 'bootstrap'
import 'bootstrap-datepicker';
import 'datatables.net';
import 'datatables.net-bs';
import '@nathanvda/cocoon';
import 'eere-app-template-mea';
import 'lib/icons';
import 'lib/flatpickrInit';
import 'glossary';
import 'date_formatter';

// Allow Rails ":remote => true" links to work from a data attribute in
// addition to the normal href. This is so we can provide a URL without an
// actual href (to prevent allowing opening in new tabs, which breaks most of
// our remote usage since we aren't responding to HTML requests).
//
// https://stackoverflow.com/questions/24227586/rails-link-to-with-remote-true-and-opening-it-in-a-new-tab-or-window
// https://github.nrel.gov/TADA/epact/pull/31
// https://www.pivotaltracker.com/n/projects/1235446/stories/137115855
Rails.href = (element) => {
  return element.dataset.href || element.href;
}

Rails.start();

// Expose libraries globally for code in HTML views.
window.jQuery = window.$ = jQuery;

// Move above z-index for EERE template site name banner (z-index 101).
$.fn.datepicker.defaults.zIndexOffset = 1000;

import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons/faArrowCircleDown';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faBus } from '@fortawesome/free-solid-svg-icons/faBus';
import { faCalculator } from '@fortawesome/free-solid-svg-icons/faCalculator';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCalendarAlt as farCalendarAlt } from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons/faCalendarCheck';
import { faCar } from '@fortawesome/free-solid-svg-icons/faCar';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons/faChevronCircleRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faClone } from '@fortawesome/free-regular-svg-icons/faClone';
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFileAlt as farFileAlt } from '@fortawesome/free-regular-svg-icons/faFileAlt';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen';
import { faGavel } from '@fortawesome/free-solid-svg-icons/faGavel';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons/faNewspaper';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons/faPhoneSquareAlt';
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faRss } from '@fortawesome/free-solid-svg-icons/faRss';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSitemap } from '@fortawesome/free-solid-svg-icons/faSitemap';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faTint } from '@fortawesome/free-solid-svg-icons/faTint';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons/faTrashAlt';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons/faMicrophone';

library.add(faArrowCircleDown);
library.add(faArrowCircleRight);
library.add(faBook);
library.add(faBuilding);
library.add(faBus);
library.add(faCalculator);
library.add(faCalendarAlt);
library.add(faCalendarCheck);
library.add(faCar);
library.add(faChartLine);
library.add(faChartPie);
library.add(faChevronCircleRight);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faClone);
library.add(faDesktop);
library.add(faDollarSign);
library.add(faDownload);
library.add(faEdit);
library.add(faEnvelope);
library.add(faExternalLinkAlt);
library.add(faFileAlt);
library.add(faFolderOpen);
library.add(faGavel);
library.add(faGraduationCap);
library.add(faInfo);
library.add(faInfoCircle);
library.add(faLock);
library.add(faMinus);
library.add(faMinusCircle);
library.add(faNewspaper);
library.add(faPencilAlt);
library.add(faPhoneSquareAlt);
library.add(faPlug);
library.add(faPlus);
library.add(faPlusCircle);
library.add(faQuestion);
library.add(faQuestionCircle);
library.add(faRss);
library.add(faSearch);
library.add(faSitemap);
library.add(faSyncAlt);
library.add(faTint);
library.add(faTrashAlt);
library.add(faUser);
library.add(faUserPlus);
library.add(faVideo);
library.add(faWrench);
library.add(farCalendarAlt);
library.add(farFileAlt);
library.add(faMicrophone);

dom.watch()
